export enum SourceIcon {
  Cat,
  Cow,
  Dog,
  Turtle,
}

export const Sources = [
  { id: 0, name: "zoomies", displayName: "Zoomies", icon: SourceIcon.Dog },
  { id: 1, name: "tippytaps", displayName: "Tippytaps", icon: SourceIcon.Dog },
  {
    id: 2,
    name: "happywoofgifs",
    displayName: "Happy dogs",
    icon: SourceIcon.Dog,
  },
  {
    id: 3,
    name: "tinyanimalsonfingers",
    displayName: "Smol animals",
    icon: SourceIcon.Turtle,
  },
  {
    id: 4,
    name: "whatswrongwithyourdog",
    displayName: "Silly dogs",
    icon: SourceIcon.Dog,
  },
  {
    id: 5,
    name: "happycowgifs",
    displayName: "Happy cows",
    icon: SourceIcon.Cow,
  },
  { id: 6, name: "supermodelcats", displayName: "Cats", icon: SourceIcon.Cat },
  {
    id: 7,
    name: "illegallysmolcats",
    displayName: "Smol cats",
    icon: SourceIcon.Cat,
  },
  {
    id: 8,
    name: "whatswrongwithyourcat",
    displayName: "Silly cats",
    icon: SourceIcon.Cat,
  },
  {
    id: 9,
    name: "tuckedinkitties",
    displayName: "Cozy cats",
    icon: SourceIcon.Cat,
  },
];
