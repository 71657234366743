import { SourceIcon } from "../sources";
import DogIcon from "../assets/icons/dogIcon.png";
import CatIcon from "../assets/icons/catIcon.png";
import CowIcon from "../assets/icons/cowIcon.png";
import TurtleIcon from "../assets/icons/turtleIcon.png";

export function getIcon(iconType: SourceIcon) {
  switch (iconType) {
    case SourceIcon.Cat:
      return CatIcon;
    case SourceIcon.Cow:
      return CowIcon;
    case SourceIcon.Dog:
      return DogIcon;
    case SourceIcon.Turtle:
      return TurtleIcon;
  }
}
