import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Source, Post, SourceWithData, SourceData } from "../types";
import { RootState } from "../store/store";
import "../styles/contentPage.scss";
import Ollie from "../assets/icons/ollieIcon.png";
import LinedPawIcon from "../assets/icons/pawLinedIcon.png";
import LeftChevronIcon from "../assets/icons/leftChevronIcon.png";
import PlayButton from "../assets/icons/playButton.png";
import PauseButton from "../assets/icons/pauseButton.png";
import classNames from "classnames";

function isSourceWithData(
	source: Source | SourceWithData
): source is SourceWithData {
	return (source as SourceWithData).posts !== undefined;
}

interface IApiState {
	posts: Post[];
	sources: Source[] | SourceWithData[];
	isFetching: boolean;
}

type IApiResponse = {
	source: Source | SourceWithData;
	data?: SourceData;
}[];

type IApiReducerAction =
	| { type: "request" }
	| { type: "success"; payload: IApiResponse };

const apiReducer: React.Reducer<IApiState, IApiReducerAction> = (
	state,
	action
) => {
	switch (action.type) {
		case "request":
			return { ...state, isFetching: true };
		case "success":
			const sourcesWithFetchedData = action.payload;
			const newPosts: Post[] = [];
			const sourcesWithNewData: SourceWithData[] = [];

			for (const sourceWithFetchedData of sourcesWithFetchedData) {
				const { source, data } = sourceWithFetchedData;
				if (data) {
					const updatedSourcePosts = isSourceWithData(source)
						? [...source.posts, ...data.posts]
						: data.posts;

					sourcesWithNewData.push({
						...source,
						posts: updatedSourcePosts,
						count: data.count,
						after: data.after,
					});

					newPosts.push(...data.posts);
				}
			}

			const shuffledPosts = _.shuffle(newPosts);

			return {
				posts: [...state.posts, ...shuffledPosts],
				sources: sourcesWithNewData,
				isFetching: false,
			};
	}
};

function useApi(
	initialSources: Source[]
): [IApiState, Post, number, () => Promise<void>, () => void] {
	const [currentPostIndex, setCurrentPostIndex] = React.useState(0);
	const [state, dispatch] = React.useReducer(apiReducer, {
		posts: [],
		isFetching: false,
		sources: initialSources,
	});

	async function fetchDataForSource(
		source: Source | SourceWithData
	): Promise<{ source: Source | SourceWithData; data?: SourceData }> {
		let requestString = `/api/posts/${source.name}`;
		if (isSourceWithData(source)) {
			// There is no more data to fetch (note this is specifically when we have a SourceWithData with no after)
			if (!source.after) return { source };
			requestString += `?after=${source.after}`;
			if (source.count) {
				requestString += `&count=${source.count}`;
			}
		}
		const response = await fetch(requestString);
		if (response.status !== 200) return { source };
		const data = await response.json();
		return { source, data };
	}

	const updateData = React.useCallback(
		async (sources: Source[] | SourceWithData[]) => {
			dispatch({ type: "request" });

			const sourcesWithFetchedData = await Promise.all(
				sources.map((source) => fetchDataForSource(source))
			);

			dispatch({ type: "success", payload: sourcesWithFetchedData });
		},
		[]
	);

	React.useEffect(() => {
		updateData(initialSources);
	}, [updateData, initialSources]);

	async function getNextPost() {
		if (currentPostIndex + 1 === state.posts.length) {
			await updateData(state.sources);
		}
		setCurrentPostIndex((oldIndex) => oldIndex + 1);
	}

	function getPrevPost() {
		if (currentPostIndex > 0) {
			setCurrentPostIndex((oldIndex) => oldIndex - 1);
		}
	}
	return [
		state,
		state.posts[currentPostIndex],
		currentPostIndex,
		getNextPost,
		getPrevPost,
	];
}

function ContentPage() {
	const [isSlideshowMode, setIsSlideshowMode] = React.useState(false);
	const [isLoaded, setIsLoaded] = React.useState(false);
	const videoRef = React.useRef<HTMLVideoElement | null>(null);

	const slideshowModeTimer = 3000;

	const selectedSources = useSelector(
		(state: RootState) => state.selectedSource.value
	);

	const [
		{ posts, isFetching, sources },
		currentPost,
		currentPostIndex,
		getNextPost,
		getPrevPost,
	] = useApi(selectedSources);

	/*
  function classNames(...classNames: (string | null)[]) {
    return _.trim(classNames.join(), ",");
  }
  */

	// TODO: Render spinner when content is being fetched (use onLoadStart, onCanPlay, and onLoad events)
	// TODO: Add visible indicator of slideshow mode timer
	// TODO: Prefetch posts
	return (
		<div className="content-page">
			<Link to="/" className="back-link">
				<img src={LeftChevronIcon} alt="" />
				<span>Back to search</span>
			</Link>
			<div className="media-container">
				{isFetching && <OllieSpinner />}
				{currentPost && !isFetching && (
					<>
						{currentPost.type === "video" ? (
							<video
								ref={videoRef}
								className={classNames("media", isLoaded && "loaded")}
								src={currentPost.url}
								controls
								autoPlay
								loop={!isSlideshowMode}
								muted
								preload="auto"
								onLoadedData={() => {
									setIsLoaded(true);
								}}
								onEnded={async () => {
									await setTimeout(async () => {
										if (isSlideshowMode) await getNextPost();
									}, slideshowModeTimer);
								}}
								onError={() => getNextPost()}
							/>
						) : (
							<img
								loading="eager"
								className={classNames("media", isLoaded && "loaded")}
								src={currentPost.url}
								alt="cute pic"
								onLoad={async () => {
									setIsLoaded(true);
									if (isSlideshowMode) {
										await setTimeout(async () => {
											if (isSlideshowMode) await getNextPost();
										}, slideshowModeTimer);
									}
								}}
							/>
						)}
					</>
				)}
			</div>
			<div className="buttons-container">
				<button
					className="media-control-button"
					onClick={() => {
						setIsLoaded(false);
						getPrevPost();
					}}
					disabled={isFetching || isSlideshowMode || currentPostIndex === 0}
				>
					<img className="prev-button-icon" src={LinedPawIcon} alt="prev" />
				</button>
				<button
					className="media-control-button"
					onClick={async () => {
						if (!isSlideshowMode) {
							// If we are enabling slideshow mode on an image we will need to fetch the next post here, as the onLoad method will not be called
							if (currentPost?.type === "image")
								await setTimeout(async () => {
									if (!isSlideshowMode) await getNextPost();
								}, slideshowModeTimer);
							// Need to stop loop to fire onEnd event when enabling slideshow mode on a video
							else if (videoRef?.current) {
								videoRef.current.loop = false;
							}
						}

						setIsSlideshowMode((oldState) => !oldState);
					}}
				>
					<img
						className="slideshow-button-icon"
						src={isSlideshowMode ? PauseButton : PlayButton}
						alt={isSlideshowMode ? "Pause" : "Play"}
					/>
				</button>
				<button
					className="media-control-button"
					onClick={() => {
						setIsLoaded(false);
						getNextPost();
					}}
					disabled={
						isFetching ||
						isSlideshowMode ||
						(currentPostIndex === posts.length - 1 &&
							!sources?.some((s) => !!(s as SourceWithData).after))
					}
				>
					<img className="next-button-icon" src={LinedPawIcon} alt="next" />
				</button>
			</div>
		</div>
	);
}

function OllieSpinner() {
	return <img className="ollie-spinner" src={Ollie} alt="Loading..." />;
}

export default ContentPage;
