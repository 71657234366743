import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { selectSources } from "../store/selectedSourcesSlice";
import { RootState } from "../store/store";
import { Sources } from "../sources";
import "../styles/searchPage.scss";
import { Source } from "../types";
import { getIcon } from "../utils/getIcon";
import pawButtonIcon from "../assets/icons/pawButtonIcon.png";

function SearchPage(): JSX.Element {
  const [selectedSources, setSelectedSources] = React.useState(
    useSelector((state: RootState) => state.selectedSource.value)
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function toggleSource(source: Source) {
    setSelectedSources((oldSources) => {
      if (!!oldSources.find((s) => s.id === source.id)) {
        return [...oldSources.filter((s) => s.id !== source.id)];
      } else {
        return oldSources.concat(source);
      }
    });
  }

  return (
    <div className="search-page">
      <h2>I want to see...</h2>
      <div className="sources-container">
        {Sources.map((source) => {
          const icon = getIcon(source.icon);
          return (
            <React.Fragment key={source.id}>
              <input
                type="checkbox"
                id={source.id.toString()}
                name={source.name}
                onChange={() => toggleSource(source)}
                checked={!!selectedSources.find((s) => s.id === source.id)}
              />
              <label className="styled-checkbox" htmlFor={source.id.toString()}>
                <img src={icon} alt="icon" />
                <span>{source.displayName}</span>
              </label>
            </React.Fragment>
          );
        })}
      </div>
      <button
        className="search-button"
        onClick={() => {
          dispatch(selectSources(selectedSources));
          navigate("/posts");
        }}
      >
        <img src={pawButtonIcon} alt="" />
        <span className="button-text">Show</span>
      </button>
    </div>
  );
}

export default SearchPage;
