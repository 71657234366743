import React from "react";
import "./styles/App.scss";
import { Outlet } from "react-router-dom";
import Ollie from "./assets/icons/ollieIcon.png";

function App() {
	return (
		<main>
			<header>
				<img src={Ollie} alt="Ollie smiling" />
				<h1>Cute!</h1>
			</header>
			<Outlet />
			{/* TODO: Add footer content */}
			<footer />
		</main>
	);
}

export default App;
