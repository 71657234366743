import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Source } from "../types";
import { Sources } from "../sources";

export interface SelectedSourcesState {
  value: Source[];
}

const initialState: SelectedSourcesState = {
  value: [],
};

export const selectedSourcesSlice = createSlice({
  name: "selectedSources",
  initialState,
  reducers: {
    toggleSource: (state, action: PayloadAction<Source>) => {
      if (state.value.find((s) => s.id === action.payload.id))
        state.value = state.value.filter((s) => s.id !== action.payload.id);
      else state.value = state.value.concat(action.payload);
    },
    toggleAllSources: (state) => {
      if (state.value.length === Sources.length) {
        state.value = [];
      } else {
        state.value = Sources;
      }
    },
    selectSources: (state, action: PayloadAction<Source[]>) => {
      state.value = action.payload;
    },
  },
});

export const { toggleSource, toggleAllSources, selectSources } =
  selectedSourcesSlice.actions;

export default selectedSourcesSlice.reducer;
